<template>
  <b-modal ref="deleteModal" id="delete-modal" title="Confirm Deletion" hide-footer>
    <div class="d-block text-center">
      <p>Are you sure you want to delete this program?</p>
    </div>
    <div class="d-block text-center">
      <inquiry-overview :inquiry="selectedInquiryToDelete"></inquiry-overview>
    </div>
    <div class="row">
      <div class="col">
        <b-button variant="secondary" @click="cancelDeletion">Cancel</b-button>
      </div>
      <div class="col">
        <b-container class="text-right mb-2">
          <b-button variant="danger" @click="deleteConfirmed">Delete</b-button>
        </b-container>
      </div>
    </div>
  </b-modal>
</template>

<script>
import InquiryOverview from "@underwriters/src/components/InquiryOverview";
export default {
  name: "DeletionConfirmationModal",
  components: {InquiryOverview},
  props: {
    selectedInquiryToDelete: {
      type: Object
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show("delete-modal");
    },
    deleteConfirmed() {
      this.$emit("delete-confirmed");
      this.$refs.deleteModal.hide();
    },
    cancelDeletion() {
      this.$refs.deleteModal.hide();
    }
  }
};
</script>
