<template>
  <div class="coverage-document-header-details">
    <div class="row row-xs">
      <div v-if="!coverageDocumentExists" class="col">
        No coverage document attached to this local policy
      </div>
    </div>
    <div v-if="coverageDocumentExists" class="row row-xs">
      <data-row large-label v-if="lossLimit > 0">
        <template slot="name">Loss Limit&nbsp;<small>({{ currencyCode }})</small></template>
        <template slot="value">{{ formatThousandsMixin(lossLimit) }}</template>
      </data-row>
      <data-row large-label v-if="aggregate > 0">
        <template slot="name">Aggregate Limit&nbsp;<small>({{ currencyCode }})</small></template>
        <template slot="value">{{ formatThousandsMixin(aggregate) }}</template>
      </data-row>
      <data-row large-label v-if="deductibleLimit > 0">
        <template slot="name">Deductible&nbsp;<small>({{ currencyCode }})</small></template>
        <template slot="value">{{ formatThousandsMixin(deductibleLimit) }}</template>
      </data-row>
      <data-row large-label v-if="turnover > 0">
        <template slot="name">Turnover&nbsp;<small>({{ currencyCode }})</small></template>
        <template slot="value">{{ formatThousandsMixin(turnover) }}</template>
      </data-row>
      <data-row large-label v-if="complexCovDoc">
        <template slot="name">Producing Office Share % <small>(where applicable)</small></template>
        <template slot="value">{{ poShare }}</template>
      </data-row>
      <data-row large-label v-if="complexCovDoc">
        <template slot="name">Policy Type</template>
        <template slot="value">{{ policyType }}</template>
      </data-row>
      <data-row large-label v-if="shouldShowCountriesCovered">
        <template slot="name">Countries Covered</template>
        <template slot="value">
          {{ fosCountriesCovered }}
          <div v-if="localPolicy.flag_FOS_all_eea_country_coverage==0">
            <br />
            <em>{{ fosCountries }}</em>
          </div>
        </template>
      </data-row>
    </div>
  </div>
</template>
<script>
import DataRow from "@common/src/components/DataRow";

const POLICY_TYPE_ALL_RISK = 1;
const POLICY_TYPE_FLEXA = 2;
const POLICY_TYPE_MANUSCRIPT = 3;
const POLICY_TYPE_OTHER = 4;

export default {
  name: "CoverageDocumentHeaderDetails",
  components: {
    DataRow,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    }
  },
  computed: {
    coverageDocument() {
      return this.$store.getters.coverageDocuments.find(document => {
        return document.coverage_document_header_id==this.id;
      });
    },
    coverageDocumentExists() {
      return this.coverageDocument!==undefined;
    },
    coverageDocumentId() {
      return this.coverageDocumentExists ? this.coverageDocument.coverage_document_header_id : 'Not created yet';
    },
    lossLimit() {
      return this.coverageDocumentExists ? this.coverageDocument.dec_loss_limit : ' - ';
    },
    deductibleLimit() {
      return this.coverageDocumentExists ? this.coverageDocument.dec_deductible : ' - ';
    },
    turnover() {
      return this.coverageDocumentExists ? this.coverageDocument.dec_turnover : ' - ';
    },
    aggregate() {
      return this.coverageDocumentExists ? this.coverageDocument.dec_aggregate : ' - ';
    },
    poShare() {
      return this.coverageDocument?.dec_po_share_pc?.length > 0 ? this.coverageDocument.dec_po_share_pc : ' - ';
    },
    localPolicy() {
      return this.$store.getters.localPoliciesForProgramme.find(policy => policy.local_policy_id==this.coverageDocument?.local_policy_id)
    },
    programme() {
      return this.$store.getters.programmes.find(policy => policy.id==this.coverageDocument?.master_policy_id)
    },
    currencyCode() {
      if (this.programme) {
        return this.programme.currencyCode;
      }
      return this.localPolicy ? this.localPolicy.currency.currency_code : 'NA';
    },
    complexCovDoc() {
      return this.coverageDocument?.flag_complex == 1 && process.env.VUE_APP_ICEDE_CLIENT === 'STARR';
    },
    shouldShowCountriesCovered() {
      return this.localPolicy.flag_freedom_of_service == 1 &&  process.env.VUE_APP_ICEDE_CLIENT === 'TMG';
    },
    fosCountriesCovered() {
      return this.localPolicy?.flag_FOS_all_eea_country_coverage == 1
          ? 'All EEA Countries' : 'Named FOS Countries';
    },
    fosCountries: function() {
      if (!this.localPolicy || !this.localPolicy.countries || this.localPolicy.countries.length === 0) {
        return [];
      }
      let sFOSCountries = '';
      const countryCount = this.localPolicy.countries.length;
      for (let i = 0; i < countryCount - 1; i++) {
        sFOSCountries += this.localPolicy.countries[i].country_name;
        if (countryCount > 2) {
          sFOSCountries += ', ';
        } else if (countryCount === 2) {
          sFOSCountries += ' ';
        }
      }
      sFOSCountries += 'and ' + this.localPolicy.countries[countryCount - 1].country_name;
      return sFOSCountries;
    },
    policyType() {
      switch (this.coverageDocument.coverage_type_id) {
        case POLICY_TYPE_ALL_RISK:
          return "All Risk";
        case POLICY_TYPE_FLEXA:
          return "FLEXA";
        case POLICY_TYPE_MANUSCRIPT:
          return "Manuscript";
        case POLICY_TYPE_OTHER:
          return this.coverageDocument.coverage_type_other;
        default:
          return "N/A";
      }
    }
  },
}
</script>
