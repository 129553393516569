<template>
  <div class="coverage-document">
    <em v-if="!coverageDocumentLoaded">Getting Coverage Document</em>
    <b-overlay spinner-small rounded="lg" :show="!coverageDocumentLoaded">
      <div class="mt-2" v-if="!showPerils && coverageDocumentLoaded">
        <coverage-document-header-details :id="coverageDocumentId" />
        <router-link v-if="coverageDocumentExists" :to="{name: 'Coverage Document', params: {programmeId, localPolicyId, coverageDocumentId: coverageDocumentId}}">
          <book-icon size="1x" />&nbsp;View Coverage Document
        </router-link>
      </div>
      <div v-if="showPerils && coverageDocumentExists">
        <div v-if="starrMode && coverageDocument.flag_policy_layers_applicable" class="row row-xs">
          <div class="col">
            <card>
              <h5>Policy Layers</h5>
              <coverage-policy-layers :layers="coverageDocument.policy_layers"
                                      :currency="'USD'"
              />
            </card>
          </div>
        </div>
        <div class="row row-xs" :class="{'mt-2': coverageDocument.flag_policy_layers_applicable==1}">
          <div class="col">
            <card>
              <h5><check-icon />&nbsp;Included Perils</h5>
              <coverage-included-perils
                  @perilselected="(perilId) => viewDefinition(perilId)"
                  :coverage-document="coverageDocument"
                  :perils="includedPerils"
                  :complex="coverageDocument.flag_complex==1"
                  :currency="programme.currencyCode"
              />
            </card>
          </div>
        </div>
      </div>
      <div v-if="showPerils && coverageDocumentExists">
        <div class="row row-xs mg-t-10">
          <div class="col">
            <card>
              <h5><x-icon />&nbsp;Excluded Perils</h5>
              <span v-for="(peril, index) in excludedPerils" :key="index">
                <button class="btn btn-xs btn-warning mr-1 mb-1"
                        @click="viewDefinition(peril.coverage_document_detail_id)"
                        v-b-tooltip:hover
                        title="View peril definition"
                >
                  <book-open-icon size="1x" />&nbsp;{{ peril.peril_caption }}
                </button>
              </span>
              <span v-if="excludedPerils.length === 0">There are no explicity excluded perils</span>
            </card>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal id="modal-peril-definition" hide-footer ref="modal-peril-definition" size="md">
      <template v-slot:modal-header>
        <h2><book-open-icon />&nbsp;Peril Definition</h2>
      </template>
      <h5>Peril Name</h5>
      {{ selectedPeril.peril_caption }}
      <h5 class="mt-2">Definition</h5>
      {{ selectedPeril.peril_definition }}
      <h5 class="mt-2">Peril ID</h5>
      {{ selectedPeril.coverage_company_peril_id }}
    </b-modal>
  </div>

</template>
<script>
import {BookOpenIcon, CheckIcon, XIcon, BookIcon} from 'vue-feather-icons';
import CoverageDocumentHeaderDetails from "@common/src/components/CoverageDocumentHeaderDetails";
import CoveragePolicyLayers from "@common/src/components/CoveragePolicyLayers";
import CoverageIncludedPerils from "@common/src/components/CoverageIncludedPerils";

const PERIL_STATE_EXCLUDED = 0;
const PERIL_STATE_INCLUDED = 1;
// const PERIL_STATE_POOL = 2;
// const PERIL_STATE_WRITE_BACK = 3;

export default {
  name: 'CoverageDocument',
  components: {
    CoverageIncludedPerils,
    CoverageDocumentHeaderDetails,
    CoveragePolicyLayers,
    BookOpenIcon,
    CheckIcon,
    XIcon,
    BookIcon
  },
  props: {
    programmeId: {
      type: [Number, String],
      default: 0,
    },
    localPolicyId: {
      type: [Number, String],
      default: 0,
    },
    showPerils: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    coverageDocumentLoaded: false,
    selectedPerilId: 0,
  }),
  methods: {
    viewDefinition(perilId) {
      this.selectedPerilId = perilId;
      this.$root.$emit("bv::show::modal", "modal-peril-definition");
    },
  },
  computed: {
    coverageDocument() {
      return this.$store.getters.coverageDocuments.find(document => {
        return document.master_policy_id==this.programmeId
            && (this.localPolicyId==0 || document.local_policy_id==this.localPolicyId);
      });
    },
    includedPerils() {
      return this.coverageDocument ? this.coverageDocument.coverage_details.filter(peril => peril.flag_peril_state == PERIL_STATE_INCLUDED) : [];
    },
    excludedPerils() {
      return this.coverageDocument ? this.coverageDocument.coverage_details.filter(peril => peril.flag_peril_state == PERIL_STATE_EXCLUDED) : [];
    },
    localPolicy() {
      return this.$store.getters.localPoliciesForProgramme.find(lPol => lPol.local_policy_id == this.localPolicyId);
    },
    coverageDocumentExists() {
      return this.coverageDocumentLoaded && this.coverageDocument!==undefined;
    },
    coverageDocumentId() {
      return this.coverageDocumentExists ? this.coverageDocument.coverage_document_header_id : 'Not created yet';
    },
    programme() {
      const prog  = this.$store.getters.programmes.find(prog =>
          (prog.id==this.programmeId)
      );
      return prog ?? {};
    },
    selectedPeril() {
      return this.coverageDocument ? this.coverageDocument.coverage_details
          .find(peril => peril.coverage_document_detail_id==this.selectedPerilId) ?? {} : {};
    },
    starrMode() {
      return process.env.VUE_APP_ICEDE_CLIENT === 'STARR';
    },
  },
  watch: {
    programmeId: async function(newId) {
      this.coverageDocumentLoaded = false;
      const context = this;
      await this.$store.dispatch(
          'loadCoverageDocument',
          {programmeId: newId, localPolicyId: this.localPolicyId}
      ).finally(
          function () {
            context.coverageDocumentLoaded = true;
          }
      );
    },
    localPolicyId: async function(newId) {
      this.coverageDocumentLoaded = false;
      const context = this;
      await this.$store.dispatch(
          'loadCoverageDocument',
          {programmeId: this.programmeId, localPolicyId: newId}
      ).finally(function() {
        context.coverageDocumentLoaded = true;
      });
    },
  },
  async mounted() {
    this.coverageDocumentLoaded = false;
    const context = this;
    await this.$store.dispatch(
        'loadCoverageDocument',
        {programmeId: this.programmeId, localPolicyId: this.localPolicyId}
    ).finally(function() {
      context.coverageDocumentLoaded = true;
    });
  }
}
</script>
