<template>
  <div class="col-sm-12 col-md-6">
    <div class="row row-xs">
      <div :class="{'col-6': largeLabel, 'col-4': !largeLabel}">
        <h6><slot name="name"></slot></h6>
      </div>
      <div :class="{'col-6': largeLabel, 'col-8': !largeLabel}">
        <slot name="value"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DataRow",
  props: {
    largeLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>
