<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0 card-title">
            {{title}}
          </h3>
        </div>
        <div v-if="tableData.length > 0" class="col text-right">
          <base-button type="primary" size="sm" @click="toggleSeeAllProgrammes">
            See {{ programmePaginationViewText }}
          </base-button>
        </div>
      </div>
    </div>

    <div class="card-body">

      <div v-if="tableData.length > 0">

        <b-row align-v="end" align-h="end" >
          <b-col lg="4" class="my-1">
            <b-form-group>
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="table-responsive">
          <b-table class="table table-hover align-items-center table-flush"
                   tbody-classes="list"
                   :fields="fields"
                   :items="tableData"
                   :filter="filter"
                   :key="detailsShown"
                   :per-page="numResultsPerPage"
                   :current-page="currentPage"
                   @filtered="onFiltered"
                   stacked="md"
          >
            <template #cell(name)="row">
              <div class="media align-items-center">
                <div class="media-body">
                  <router-link
                      v-if="row.item.mpol"
                      :to="{name: 'Programme Overview', params: {programmeId: row.item.mpol.id}}"
                      class="name mb-0 text-sm"
                  >
                    {{row.item.name}}
                  </router-link>
                  <div v-else>
                    {{row.item.name}}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(countries)="{ value }">
              <span v-html="value"></span>
            </template>
            <template #cell(created_at)="{ value }">
              {{ value ? formatDateUKMixin(value) : ' - ' }}
            </template>
            <template #cell(live)="row">
              <live-badge :inception="row.item.inception" :expiry="row.item.expiry" />
            </template>
            <template v-if="showPremiumDataInLists()" #cell(mpol.premium)="row">
              <div v-if="row.item.mpol">
                {{ row.item.mpol.currencyCode }} {{ formatThousandsMixin(row.item.mpol.premium) }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template #cell(po_handler)="row">
              <div v-html="row.item.handler"></div>

            </template>
            <template #cell(mpol.policy_bind_state_id)="row">
              <span v-html="row.item.policyBindStateCaption"></span>
            </template>

            <template #cell(bind)="row">
              <div v-if="row.item.mpol && parseInt(row.item.mpol.policy_bind_state_id) === MASTER_POLICY_BIND_STATE.PROSPECT" class="text-center">
                <b-button size="sm" variant="primary" @click="openBindPolicyModal(row.item.id)">
                  <i class="fa fa-link"></i>&nbsp;
                  Confirm Bind
                </b-button>
              </div>
            </template>

            <template #cell(additional_info)="row">
              <button
                  class="btn btn-success btn-sm mr-1"
                  @click="goToAddFileToInquiry(row.item.id)"
              >
                <i class="fa fa-folder-open"></i>&nbsp;Inquiry
              </button>


              <router-link
                  v-if="row.item.mpol"
                  :to="{name: 'Programme Overview', params: {programmeId: row.item.mpol.id}}"
                  class="name mb-0 text-sm"
              >
                <button
                    class="btn btn-success btn-sm mr-1"
                >
                  <i class="fa fa-folder-open"></i>&nbsp;Programme
                </button>
              </router-link>
            </template>
          </b-table>

          <b-pagination
              v-model="currentPage"
              :total-rows="rowCount"
              :per-page="numResultsPerPage"
              class="my-4 justify-content-center"
          ></b-pagination>
        </div>

      </div>
      <div v-else-if="loadingProgrammes" class="p-5 text-center">
        <b-spinner></b-spinner>
        <br>
        <div class="pt-2">
          <em>Loading {{ title }}</em>
        </div>
      </div>
      <div v-else class="p-5 text-center">
        You do not currently have any Instructed Accounts.
      </div>
    </div>


    <additional-info-modal
        :inquiryId="additionalInfoSelectedInquiryId"
        :modalShow="showAdditionalInfoModal"
        @hideModal="closeAdditionalInfoModal"
    ></additional-info-modal>

    <bind-policy-modal
        :inquiryId="bindPolicySelectedInquiryId"
        :modalShow="showBindPolicyModal"
        @hideModal="closeBindPolicyModal"
    ></bind-policy-modal>

  </div>
</template>
<script>
import LiveBadge from "@common/src/components/LiveBadge";
import FeaturesMixin from '@clients/src/mixins/features.mixin';
import {InfoIcon} from "vue-feather-icons";
import AdditionalInfoModal from "@underwriters/src/components/AdditionalInfoModal";
import BindPolicyModal from "@underwriters/src/components/BindPolicyModal";
import { MASTER_POLICY_BIND_STATE } from "@common/src/constants";
import axios from "axios";
import FormatterMixin from "@underwriters/src/mixins/formatter.mixin";

const defaultProgrammesPerPage = 4;

export default {
  name: 'projects-table',
  components: {
    LiveBadge,
    AdditionalInfoModal,
    BindPolicyModal,
    InfoIcon
  },
  mixins: [FeaturesMixin, FormatterMixin],
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    perPage: {
      type: Number,
      default: defaultProgrammesPerPage
    },
    type: {
      type: String,
    },
    title: String,
    loadingProgrammes: Boolean,
  },
  created() {
    this.MASTER_POLICY_BIND_STATE = MASTER_POLICY_BIND_STATE;
  },
  methods: {
    metricCounts(programme, metricName) {
      programme = programme.mpol;
      if (!programme[metricName]) {
        return [1,1,1];
      }
      return [
        programme[metricName].G ?? 1,
        programme[metricName].A ?? 1,
        programme[metricName].R ?? 1,
        programme[metricName].S ?? 1,
      ]
    },
    toggleSeeAllProgrammes() {
      if(this.numResultsPerPage === this.tableData.length) {
        this.numResultsPerPage = this.perPage;
        this.programmePaginationViewText = 'all';
      } else {
        this.numResultsPerPage = this.tableData.length;
        this.programmePaginationViewText = 'first page';
      }
    },
    toggleDetails(row) {
      row.toggleDetails();
      this.detailsShown = !this.detailsShown;
    },
    openAdditionalInfoModal(inquiryId) {
      this.additionalInfoSelectedInquiryId = inquiryId;
      this.showAdditionalInfoModal = true;
    },
    closeAdditionalInfoModal() {
      this.additionalInfoSelectedInquiryId = null;
      this.showAdditionalInfoModal = false;
    },
    openBindPolicyModal(inquiryId) {
      this.bindPolicySelectedInquiryId = inquiryId;
      this.showBindPolicyModal = true;
    },
    closeBindPolicyModal() {
      this.bindPolicySelectedInquiryId = null;
      this.showBindPolicyModal = false;
    },
    onFiltered(filteredItems) {
      this.rowCount = filteredItems.length
      this.currentPage = 1
    },
    async goToAddFileToInquiry(inquiryId) {

      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/" + inquiryId;
      this.loadingStateId = inquiryId;
      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        if (data.state) {
          await this.$store.dispatch("loadState", data);
          if (
              data.state.localPolicies.completionStatus &&
              data.state.localPolicies.completionStatus !== "prospect"
          ) {
            this.$toasted.info(
                "This Programme has already been submitted"
            );
          }

          this.loadingStateId = 0;

          await this.$router.push({
            name: "Add Data to Inquiry",
            params: { inquiryId: inquiryId }
          });

        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    tableData(newValue) {
      this.rowCount = newValue.length;
    }
  },
  data: function() {
    let fields = [
      {key: 'name', label: 'Programme Name', sortable: true},
      {key: 'mpol.class', label: 'Class', sortable: true},
      {key: 'countries', label: 'Countries', sortable: true},
      {key: 'created_at', label: 'Created', sortable: true},
      {key: 'period', label: 'Period', sortable: true},
    ];
    if (this.showPremiumDataInLists()) {
      fields.push({key: 'mpol.premium', label: 'Total Premium', sortable: true});
    }
    fields.push(
      {key: 'po_handler', label: 'PO Handler', sortable: true},
      {key: 'mpol.policy_bind_state_id', label: 'Status', sortable: true},
      {key: 'bind', label: '', sortable: false},
      {key: 'additional_info', label: '', sortable: false},
    );
    return {
      currentPage: 1,
      fields,
      items: this.tableData,
      slaNumberExplanation: 'Number of transactions within SLA shown in green & amber, with red transactions outside of SLA',
      numResultsPerPage: this.perPage,
      programmePaginationViewText: 'all',
      filter: null,
      detailsShown: false,
      additionalInfoSelectedInquiryId: null,
      showAdditionalInfoModal: false,
      bindPolicySelectedInquiryId: null,
      showBindPolicyModal: false,
      rowCount: this.tableData.length,
    }
  },
}
</script>
<style>
</style>
