<template>
  <table class="table table-hover">
    <thead>
    <tr>
      <th>Programme Name</th>
      <th>Class</th>
      <th v-if="showPremiumDataInLists()">Currency</th>
      <th v-if="showPremiumDataInLists()">Total Premium</th>
      <th>Inception - Expiry</th>
      <th>Live</th>
      <th>Local Policy Count</th>
    </tr>
    </thead>
    <tbody v-if="programmes.length > 0">
    <tr
        v-for="prog in programmes"
        :key="prog.id"
    >
      <td>
        <router-link
            :to="{name: 'Programme Overview', params: {programmeId: prog.id}}"
            class="name mb-0 text-sm"
            v-b-tooltip:hover title="View Programme"
        >
          {{prog.name}}
        </router-link>
      </td>
      <td>{{ prog.class }}</td>
      <td v-if="showPremiumDataInLists()">{{ prog.currencyCode }}</td>
      <td v-if="showPremiumDataInLists()">{{ prog.premium | thousands }}</td>
      <td>{{ prog.inception | formatDateUK }} - {{ prog.expiry | formatDateUK }}</td>
      <td><live-badge :inception="prog.inception" :expiry="prog.expiry" /></td>
      <td>{{ prog.lpolCount }}</td>
    </tr>
    </tbody>
    <tbody v-else>
    <tr class="bg-gray-1">
      <td colspan="6" class="text-center">
        This client has no Live Programmes at present
      </td>
    </tr>
    </tbody>
  </table>

</template>
<script>
import PolicyMixin from "@clients/src/mixins/policy.mixin";
import LiveBadge from "@common/src/components/LiveBadge";
import FeaturesMixin from '@clients/src/mixins/features.mixin';

export default {
  name: "ProgrammeTable",
  components: {
    LiveBadge
  },
  props: {
    programmes: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [PolicyMixin, FeaturesMixin],
  methods: {
    contactName(contact) {
      if (contact === undefined) {
        return ' - ';
      }

      return contact.forename + ' ' + contact.surname;
    },
  },
}
</script>
