<template>
  <div class="key-contacts-read-only">
    <!-- Client and broker row per local policy   -->
    <div
        v-for="localPolicy in localPolicies"
        :key="localPolicy.local_policy_id"
        class="row"
    >
      <div class="col">
        <div v-if="localPolicies.length!==1" class="row">
          <div class="col">
            <small>{{ localPolicy.country + ' - ' + localPolicy.local_policy_id}}</small>
          </div>
        </div>
        <div class="row">
          <div
              v-for="(contact, key) in contacts(localPolicy.local_policy_id)"
              :key="key + localPolicy.local_policy_id"
              class="col-xl-6"
          >
            <div class="row">
              <div class="col">
                <h6>{{ key }}</h6>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Name</label>
              <div class="col pt-2">
                {{ contact.contact_name.value }}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Company</label>
              <div class="col pt-2">
                {{ contact.company_name.value }}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Address</label>
              <div class="col pt-2">
                {{ contact.address.value }}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Telephone</label>
              <div class="col pt-2">
                {{ contact.telephone.value }}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label font-weight-bold">Email</label>
              <div class="col pt-2">
                {{ contact.email.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'KeyContactsReadOnly',
  props: {
    localPolicies: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    localContacts: [],
  }),
  methods: {
    contacts(lPolId) {
      const contact = this.localContacts.find(cont => cont.id==lPolId);
      return contact?.contacts ?? {};
    },
    initLocalContacts() {
      this.localContacts = [];
      for (let lPol of this.localPolicies) {
        const contacts = {
          changes: false,
          id: lPol.local_policy_id,
          contacts:
              {
                Client: {},
                Broker: {},
              }
        };
        for (let field in lPol.key_contacts) {
          const key = field.substr(7);
          if (field.startsWith('broker_')) {
            contacts.contacts.Broker[key] = {};
            contacts.contacts.Broker[key]['value'] = lPol.key_contacts[field] ?? ' - ';
            contacts.contacts.Broker[key]['changes'] = false;
          } else if (field.startsWith('client_')) {
            contacts.contacts.Client[key] = {};
            contacts.contacts.Client[key]['value'] = lPol.key_contacts[field] ?? ' - ';
            contacts.contacts.Client[key]['changes'] = false;
          }
        }
        this.localContacts.push(contacts);
      }
    }
  },
  watch: {
    localPolicies: function() {
      this.initLocalContacts();
    }
  },
  mounted() {
    this.initLocalContacts();
  }
}
</script>
