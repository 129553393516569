<template>
  <span class="status-badge badge" :class="level">
    {{ statusText }}
  </span>
</template>
<script>
const COMPLETED = 1;
const FINALISED = 2;
const ISSUED = 4;
const COLLECTED = 8;
const EXPIRED = 16;

export default {
  name: "StatusBadge",
  props: {
    statusMask: {
      type: Number,
      default: 0,
    }
  },
  data: () => ({
    statusText: '',
    statusLevel: '',
  }),
  computed: {
    level() {
      return 'badge-' + this.statusLevel;
    }
  },
  methods: {
    loadStatus() {
      if (this.statusMask & EXPIRED) {
        this.statusText = 'Expired';
        this.statusLevel = 'secondary';
        return;
      }

      if (this.statusMask & FINALISED) {
        this.statusLevel = 'success'
        this.statusText = (this.statusMask & ISSUED) ? 'Issued' : 'Not Issued';
        this.statusText += ' and ';
        this.statusText += (this.statusMask & COLLECTED) ? 'Collected' : 'Not Collected';
        return;
      }

      if (this.statusMask & COMPLETED) {
        this.statusLevel = 'primary';
        this.statusText = 'Not Finalised';
      } else {
        this.statusLevel = 'primary'
        this.statusText = 'Not Completed';
      }
    }
  },
  watch: {
    statusMask() {
      this.loadStatus();
    }
  },
  mounted() {
    this.loadStatus();
  }
}
</script>
