<template>
  <div class="home">
    <card type="primary">
      <div class="row" id="root">
        <div class="col">
          <h3 class="card-title">Saved Programme Inquiries</h3>
          <br />
          <span
            >Please find a list of your instructed inquiries below, you may
            re-open and continue working on one or choose to delete it.</span
          >
        </div>
        <div class="col text-right">
          <button
            class="btn btn-sm btn-outline-secondary"
            @click="reloadProgrammes"
          >
            <i class="fa fa-sync"></i>&nbsp;Refresh Programmes
          </button>
        </div>
      </div>
      <br />
      <div v-if="$store.getters.isLoggedIn" class="row">
        <div class="col">
          <div v-if="!loadingProgrammes">
            <b-row>
              <b-col lg="8">
                <h5 class="text-muted">Prospect Programmes</h5>
              </b-col>

              <b-col v-if="prospectProgrammes.length > 0">
                <b-form-group>
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filterProspectProgrammes"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filterProspectProgrammes"
                        @click="filterProspectProgrammes = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <span v-if="loadingProgrammes && programmeList.length === 0">
            <i class="fa fa-sync fa-spin"></i>&nbsp;Loading Programme
            Inquiries...
          </span>
          <div v-if="!loadingProgrammes && prospectProgrammes.length > 0">
            <b-table
              striped
              hover
              :items="prospectProgrammes"
              :fields="programmesTableFields"
              :filter="filterProspectProgrammes"
            >
              <template v-slot:cell(countries)="{ value }">
                <span v-html="value"></span>
              </template>

              <template v-slot:cell(po_handler)="{ value }">
                <div v-html="value"></div>
              </template>

              <template v-slot:cell(status)="{ item }">
                <span
                  class="badge"
                  :class="{
                    'badge-primary': item.status === 'Assigned',
                    'badge-secondary': item.status === 'Pending'
                  }"
                  >{{ item.status }}</span
                >
              </template>

              <template v-slot:cell(actions)="{ value }">
                <button
                  class="btn btn-success btn-sm mr-1"
                  @click="() => loadProgramme(value)"
                >
                  <i class="fa fa-folder-open"></i>&nbsp;Open
                </button>
                <button
                    class="btn btn-danger btn-sm btn-fill"
                    @click="confirmDelete(value)"
                    v-b-tooltip.hover.top="{
                      title: 'Delete this Programme Inquiry',
                      variant: 'danger'
                    }"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </template>
            </b-table>
          </div>
          <div
            v-if="!loadingProgrammes && prospectProgrammes.length < 1"
            class="p-5 text-center"
          >
            You do not currently have any Prospect Programmes.
          </div>


          <div v-if="!this.underwritingPortalMode()">
            <br /><br />

            <div v-if="!loadingProgrammes || submittedProgrammes.length > 0">
              <h5 class="text-muted">Instructed Programmes</h5>

              <b-row align-v="end" align-h="end">
                <b-col lg="4" class="my-1">
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filterSubmittedProgrammes"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filterSubmittedProgrammes"
                          @click="filterSubmittedProgrammes = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-table
                  striped
                  hover
                  :items="submittedProgrammes"
                  :fields="programmesTableFields"
                  :filter="filterSubmittedProgrammes"
              >
                <template v-slot:cell(countries)="{ value }">
                  <span v-html="value"></span>
                </template>

                <template v-slot:cell(po_handler)="{ value }">
                  <span v-html="value"></span>
                </template>

                <template v-slot:cell(status)="{ item }">
                  <span
                    class="badge"
                    :class="{
                      'badge-primary': item.status === 'Assigned',
                      'badge-secondary': item.status === 'Pending'
                    }"
                    >{{ item.status }}</span
                  >
                </template>

                <template v-slot:cell(actions)="{ value }">
                  <button
                    class="btn btn-success btn-sm mr-1"
                    @click="() => loadProgramme(value)"
                  >
                    <i class="fa fa-folder-open"></i>&nbsp;Open
                  </button>
                  <button
                    class="btn btn-danger btn-sm btn-fill"
                    @click="confirmDelete(value)"
                    v-b-tooltip.hover.top="{
                      title: 'Delete this Programme Inquiry',
                      variant: 'danger'
                    }"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </template>
              </b-table>
            </div>
            <div v-if="!loadingProgrammes && submittedProgrammes.length < 1" class="p-5 text-center">
              You do not currently have any Submitted Programmes.
            </div>
          </div>
        </div>
      </div>
    </card>

    <deletion-confirmation-modal
      :selectedInquiryToDelete="selectedInquiryToDelete"
      @delete-confirmed="deleteProgramme"
      ref="deleteModalComponent"
    ></deletion-confirmation-modal>
  </div>
</template>

<style scoped>
div.programme-link-container {
  margin: 5px 10px;
  padding: 10px 0 10px 10px;
  border-bottom: 2px solid #ddd;
}
div.programme-link-container:hover {
  background-color: #eee;
}

.country-list-container {
  max-height: 75px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.badge {
  padding: 3px 5px;
  font-size: 0.78em;
}
</style>

<script>
import axios from "axios";
import Card from "../components/Cards/Card";
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";
import FormatterMixin from "@underwriters/src/mixins/formatter.mixin";
import DeletionConfirmationModal from "@underwriters/src/components/DeletionConfirmationModal";

export default {
  name: "Home",
  components: {
    Card,
    DeletionConfirmationModal,
  },
  mixins: [FeaturesMixin, FormatterMixin],
  data: () => {
    return {
      loadingProgrammes: true,
      loadingStateId: 0,
      deletingStateId: 0,
      programmeList: [],
      filterProspectProgrammes: "",
      programmesTableFields: [
        { key: "name", label: "Inquiry Name", sortable: true },
        {
          key: "policy_class_type",
          label: "Policy Class Type",
          sortable: true
        },
        { key: "countries", label: "Countries", sortable: true },
        { key: "created", label: "Created", sortable: true },
        {
          key: "period_of_insurance",
          label: "Period of Insurance",
          sortable: true
        },
        { key: "po_handler", label: "PO Handler", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Actions" }
      ],
      filterSubmittedProgrammes: "",
      selectedInquiryToDelete: null,
    };
  },
  computed: {
    prospectProgrammes() {
      return this.programmeList
        .filter(project => project.completionStatus.name === "Prospect")
        .map(project => {
          return {
            name: project.name,
            policy_class_type: this.parseClassTypeFromProgramme(project),
            countries: project.countries,
            created: project.date_created
              ? this.formatDateUKMixin(project.date_created)
              : " - ",
            period_of_insurance:
              (project.date_inception
                ? this.$options.filters.formatDateUK(project.date_inception)
                : " - ") +
              " to " +
              (project.date_expiry
                ? this.$options.filters.formatDateUK(project.date_expiry)
                : " - "),
            status: this.getStatusLabel(project.completionStatus.name),
            po_handler: this.getHandlerFromProgramme(project),
            actions: project.id
          };
        });
    },
    submittedProgrammes() {
      return this.programmeList
        .filter(project => project.completionStatus.name === "Submitted")
        .map(project => {
          return {
            name: project.name,
            policy_class_type: this.parseClassTypeFromProgramme(project),
            countries: project.countries,
            created: project.created_at
              ? this.formatDateUKMixin(project.created_at)
              : " - ",
            period_of_insurance:
              (project.date_inception
                ? this.$options.filters.formatDateUK(project.date_inception)
                : " - ") +
              " to " +
              (project.date_expiry
                ? this.$options.filters.formatDateUK(project.date_expiry)
                : " - "),
            status: this.getStatusLabel(project.completionStatus.name),
            po_handler: this.getHandlerFromProgramme(project),
            actions: project.id
          };
        });
    }
  },
  methods: {
    async reloadProgrammes() {
      this.programmeList = [];
      await this.getProgrammeList();
    },
    async getProgrammeList() {
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/list";

      this.loadingProgrammes = true;
      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        this.programmeList = data.programmes;
        this.loadingProgrammes = false;
      } catch (err) {
        console.error(err);
      }
    },
    async loadProgramme(id) {
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/" + id;
      this.loadingStateId = id;
      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        if (data.state) {
          await this.$store.dispatch("loadState", data);
          let lastUrlName = data.state.lastUrlName;
          if (
            data.state.localPolicies.completionStatus &&
            data.state.localPolicies.completionStatus !== "prospect"
          ) {
            this.$toasted.info(
              "This Programme has already been submitted."
            );
          }
          if (this.underwritingPortalMode()) {
            await this.$router.push({ name: "Add Data to Inquiry", params: { inquiryId: id } });
          } else if (lastUrlName) {
            await this.$router.push({ name: lastUrlName });
          } else {
            await this.$router.push({ name: "Country Select" });
          }
          this.loadingStateId = 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async deleteProgramme(id) {
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/" + id;

      this.programmeList = this.programmeList.filter(
        programme => programme.id !== id
      );

      this.deletingStateId = id;
      try {
        let { data } = await axios.delete(url, {
          withCredentials: true
        });
        this.programmeList = data.programmes;
        this.deletingStateId = 0;
        this.$toasted.success("Programme successfully deleted");
      } catch (err) {
        console.error(err);
      }
    },
    parseClassTypeFromProgramme(programme) {
      const classType = programme.classType;
      if (typeof classType === "string") {
        return classType;
      }
      return classType.policy_class_type_caption;
    },
    getHandlerFromProgramme(programme) {
      let sForename = programme.mpol?.handlers?.primaryHandler?.forename ?? '';
      let sSurname = programme.mpol?.handlers?.primaryHandler?.surname ?? '';

      if (sForename || sSurname) {
        return sForename + " " + sSurname;
      } else {
        return '<em class="text-muted">Not Assigned</em>';
      }
    },
    getStatusLabel(status) {
      if (status === "Accepted") {
        return "Assigned";
      } else {
        return "Pending";
      }
    },
    confirmDelete(id) {
      this.selectedInquiryToDelete = this.programmeList.find(
        programme => programme.id === id
      );
      this.$refs.deleteModalComponent.showModal();
      this.$refs.deleteModalComponent.$on("delete-confirmed", () => {
        this.deleteProgramme(id);
      });
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.getProgrammeList();
    }
  }
};
</script>
