<template>
  <div :class="colClasses">
    <card class="text-center ht-md-100p">
      <template slot="header">
        <strong>{{ label }}</strong>
      </template>
      <b-badge v-if="badgeValue.length > 0" class="larger-text wd-100p" :variant="variant">{{ badgeValue }}</b-badge>
      <span v-else >{{ value }}</span>
    </card>
  </div>
</template>
<script>
  import Card from '@common/src/components/Card.vue';

  export default {
    name: 'policy-stats-card',
    components: {
      Card
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      variant: {
        type: String,
        default: 'secondary',
        validator: value => ['success', 'secondary', 'primary', 'warning', 'danger'].indexOf(value) !== -1,
      },
      value: {
        type: String,
        default: '',
      },
      badgeValue: {
        type: String,
        default: '',
      },
      first: {
        type: Boolean,
        default: false,
      },
      collapsedStatsColWidth: {
        type: Number,
        default: 3,
      },
    },
    computed: {
      colClasses() {
        return [
          {[`col-md-${this.collapsedStatsColWidth} col-lg-12 mt-md-2` ]: true},
          {"mt-lg-2": !this.first},
          {"mt-lg-0": this.first},
        ];
      }
    },
  };
</script>
<style scoped>
.larger-text {
  font-size: 1em;
}
</style>
