<template>
  <div>
    <div class="row row-xs">
      <div class="col">
        <card>
          <h3 class="mb-0 card-title">
            Coverage Document
          </h3>
          <em class="text-muted">On Local Policy
          <router-link :to="{
                    name: 'Local Policy Overview',
                    params: {programmeId: this.programmeId, localPolicyId: this.localPolicyId}
                  }">
            {{ localPolicyName }}
          </router-link></em>
          <card style="background-color: rgba(165,165,255,0.15);" class="mt-2" :shadow="false">
            <!-- Coverage Document Header information -->
            <div class="row row-xs mt-2">
              <data-row large-label>
                <template slot="name">Programme Name</template>
                <template slot="value">{{programme.name}}</template>
              </data-row>
              <data-row large-label>
                <template slot="name">Client</template>
                <template slot="value">{{$store.getters.client?.client_name ?? programme?.client_name}}</template>
              </data-row>
            </div>
            <coverage-document-header-details :id="coverageDocumentId" />
          </card>
          <!--      Policy list query buttons    -->
          <!--         Timeline and stats row   -->
          <!--          <div class="row">-->
          <!--            <div class="col">-->
          <!--              <policy-timeline-->
          <!--                  :programme-id="id"-->
          <!--              >-->
          <!--              </policy-timeline>-->
          <!--            </div>-->
          <!--          </div>-->
        </card>
      </div>
    </div>
    <div class="row row-xs mg-t-10">
      <div class="col">
        <coverage-document :local-policy-id="localPolicyId" :programme-id="programmeId" :show-perils="true" />
      </div>
    </div>
  </div>
</template>
<script>
import DataRow from "@common/src/components/DataRow";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import CoverageDocument from "@common/src/components/CoverageDocument";
import CoverageDocumentHeaderDetails from "@common/src/components/CoverageDocumentHeaderDetails";

export default {
  name: 'ProgrammeOverview',
  components: {
    CoverageDocumentHeaderDetails,
    CoverageDocument,
    DataRow,
  },
  props: {
    programmeId: {
      type: [Number, String],
      required: true,
    },
    localPolicyId: {
      type: [Number, String],
      required: true,
    },
    coverageDocumentId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    filter: 'All Local Policies',
    faSpinner,
  }),
  computed: {
    programme: function() {
      const prog  = this.$store.getters.programmes.find(prog => prog.id==this.programmeId);
      return prog ?? {};
    },
    localPolicy() {
      // Annoyingly, the programme ID changes type between String and Number
      //  depending on where it is set, so we have to do a "loose equality" comparison here
      return this.$store.getters.localPoliciesForProgramme.find(lPol => lPol.local_policy_id==this.localPolicyId);
    },
    localPolicyName() {
      return this.localPolicy.local_policy_name ?? 'No Name...';
    },
  },
  watch: {
    programmeId: async function(newId) {
      // This id watch function will be fired if the user changes the ID in the
      //  URL, clicks a link to the same view, or does an action that just reuses
      //  this component. When that happens, we need to check if a programme with
      //  the given ID exists, redirect if not.
      // For the above cases the beforeRouteEnter listener below is not fired.
      if (!await this.$store.dispatch(
          'programmeExists',
          {programmeId: newId}
      )
      ) {
        this.$root.$bvToast.toast(
            'That programme is not available',
            {title: 'Programme Error', variant: 'danger', autoHideDelay:5000}
        );
        this.$router.replace({name: 'Dashboard'});
      }
    }
  },
  // This listener is fired when the browser first arrives on this view from a
  //  a different route... It is also fired if a link is typed/pasted in to the
  //  overview for another programme ID.
  // The above watch id function is not fired before route enter.
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      const programmeExists = await vm.$store.dispatch(
          'programmeExists',
          {programmeId: to.params.programmeId}
      );
      if (!programmeExists) {
        vm.$root.$bvToast.toast(
            'That programme is not available',
            {title: 'Programme Error', variant: 'danger', autoHideDelay:5000}
        );
        next({name: 'Dashboard'})
      } else {
        // if (!vm.localPoliciesFetched(to.params.programmeId)) {
        //   vm.getLocalPolicies(to.params.programmeId);
        // }
        next();
      }
    });
  }
};
</script>
<style></style>
