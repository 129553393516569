import { Doughnut } from 'vue-chartjs/legacy';
import globalOptionsMixin from "@common/src/components/Charts/globalOptionsMixin";
import { Chart, LinearScale, CategoryScale, ArcElement, Tooltip } from "chart.js";

Chart.register(LinearScale, CategoryScale, ArcElement, Tooltip);

export default {
  name: 'doughnut-chart',
  extends: Doughnut,
  mixins: [globalOptionsMixin],
  props: {
    chartData: {
      type: Object,
      default() { return {}; },
    },
    data: {
      type: Array,
      default: () => [0,0,0,0]
    },
    extraOptions: {
      type: Object,
      default: () => ({})
    },
    satisfiedLabel: {
      type: String,
      default: 'Satisfied'
    }
  },
  data() {
    return {
      ctx: null
    };
  },
  computed: {
    chartDatas() {
      return {
        labels: [this.satisfiedLabel, 'Within Terms', 'Nearly Overdue', 'Overdue'],
        datasets: [{
          label: 'Status',
          data: this.data,
          backgroundColor: [
            '#' + process.env.VUE_APP_DONUT_SATISFIED,
            '#' + process.env.VUE_APP_DONUT_GREEN,
            '#' + process.env.VUE_APP_DONUT_AMBER,
            '#' + process.env.VUE_APP_DONUT_RED
          ],
          borderColor: 'white',
          borderWidth: 1,
          barPercentage: 1.6,
        }],
      }
    },
  },
  watch: {
    data() {
      this.renderChart(this.chartDatas, this.extraOptions);
    }
  },
  mounted() {
    this.renderChart(this.chartDatas, this.extraOptions);
  }
};
