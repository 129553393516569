<template>
  <b-modal v-model="modalShowModel" title="Bind Policy" hide-footer>
    <b-form @submit.prevent="submitForm">

      <p>Send request to PO Handler to bind the policy.</p>

      <hr />
      <br />

      <b-button variant="secondary" @click="hideModal()">Cancel</b-button>
      <b-button type="submit" variant="primary" class="float-right">
        Request Bind
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import UnderwritingService from "@underwriters/src/services/underwriting.service";

export default {
  name: "BindPolicyModal",
  props: {
    inquiryId: {
      type: [Number, String]
    },
    modalShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      additionalInfo: "",
      selectedFile: null,
      acceptedFileTypes: process.env.VUE_APP_ACCEPTED_FILE_TYPES,
    };
  },
  methods: {
    submitForm() {
      UnderwritingService.requestBindPolicy(this.inquiryId);

      this.hideModal();
    },
    hideModal() {
      this.$emit("hideModal");
    }
  },
  computed: {
    modalShowModel: {
      get () { return this.modalShow },
      set (value) { this.$emit('update:modalShow', value) },
    },
  }
};
</script>
