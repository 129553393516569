import ApiService from "@/services/api.service";

const UnderwritingService = {
  async sendAdditionalInfoToPOHandler(inquiryId, additionalInfo, file) {
    let formData = new FormData();
    formData.append("inquiryId", inquiryId);
    formData.append("file", file);
    formData.append("additionalInfo", additionalInfo);

    try {
      let { data } = await ApiService.post(
        "/progress/additionalInfo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (err) {
      console.error("occurred while uploading document:", err);
      return false;
    }
  },
  async requestBindPolicy(inquiryId) {
    let formData = new FormData();
    formData.append("inquiryId", inquiryId);

    try {
      let { data } = await ApiService.post(
        "/progress/requestBindPolicy",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (err) {
      console.error("occurred while sending request to bind policy:", err);
      return false;
    }
  }
};

export default UnderwritingService;
