<template>
  <div class="coverage-policy-layers">
    <table class="table table-hover table-striped">
      <thead>
      <tr>
        <th>Layer</th>
        <th>Share</th>
        <th></th>
        <th>Currency</th>
        <th>Share Value</th>
        <th></th>
        <th>Total Layer Value</th>
        <th></th>
        <th>Previous Layer</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(layer, index) in layers" :key="index">
          <td>Layer {{ index + 1 }}</td>
          <td>{{ layer.share_pc | thousands }}%</td>
          <td>that being</td>
          <td>{{ currency }}</td>
          <td>{{ layer.share_value | thousands }}</td>
          <td>part of the</td>
          <td>{{ layer.total_layer_value | thousands }}</td>
          <td></td>
          <td>{{ layer.previous_layer_value | thousands }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "CoveragePolicyLayers",
  props: {
    layers: {
      required: true,
      type: Array
    },
    currency: {
      required: true,
      type: String
    }
  }
}
</script>
