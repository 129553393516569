export const ACTIVITY_FEED_TIMEFRAME = Object.freeze({
    SINCE_LAST_LOGIN: 0,
    IN_LAST_WEEK: 1,
    IN_LAST_MONTH: 2,
});

export const MASTER_POLICY_BIND_STATE = Object.freeze({
    PROSPECT: 1,
    BOUND: 2,
    NOT_BOUND: 3
});