<template>
  <div class="policy-status-list">
    <div class="row row-xs">
      <!-- Inception/Expiry -->
      <policy-stats-card label="Inception and Expiry"
                         :first="true"
                         :value="formatDateUKMixin(localPolicy.date_inception) + ' - '
                                  + formatDateUKMixin(localPolicy.date_expiry)"
                         :collapsed-stats-col-width="collapsedStatsColWidth" />
      <!-- Finalised / Instruction Issued -->
      <policy-stats-card label="Instructions Issued"
                         :variant="timelinessVariant"
                         :badge-value="timelinessText"
                         :collapsed-stats-col-width="collapsedStatsColWidth" />

      <!-- Policy Issued -->
      <policy-stats-card label="Policy Issued"
                         :variant="policyIssuanceVariant"
                         :badge-value="policyIssuanceText"
                         :collapsed-stats-col-width="collapsedStatsColWidth" />

      <!-- Premium Collected -->
      <policy-stats-card label="Premium Collected"
                         :variant="premiumCollectedVariant"
                         :badge-value="premiumCollectedText"
                         :collapsed-stats-col-width="collapsedStatsColWidth" />
      <!-- Documents -->
      <div :class="`col-md-${collapsedStatsColWidth} col-lg-12 mt-md-2 mt-lg-2`">
        <card class="text-center ht-md-100p">
          <template slot="header">
            <strong>Documents</strong>
          </template>
          <div class="list-group">
            <button v-for="(document, index) in nonWordingDocuments"
                    :key="index"
                    :disabled="loadingDocuments.indexOf(index)!==-1"
                    @click="() => downloadDocument(index)"
                    href="#"
                    class="list-group-item list-group-item-action">
              <download-icon size="1x" />&nbsp;{{ document.document_caption }}
              <font-awesome-icon v-if="loadingDocuments.indexOf(index)!==-1" :icon="faSpinner" spin />
            </button>
            <a v-if="nonWordingDocuments.length == 0" class="list-group-item list-group-item-action text-muted">
              No documents attached to this policy
            </a>
            <a style="display:none;" href="#" ref="downloadLink">Download Link</a>
          </div>
        </card>
      </div>
      <!-- Wording -->
      <div :class="`col-md-${collapsedStatsColWidth} col-lg-12 mt-md-2 mt-lg-2`">
        <card class="text-center ht-md-100p">
          <template slot="header">
            <strong>Wording</strong>
          </template>
          <div class="list-group">
            <button v-for="(document, index) in wordingDocuments"
                    :key="index"
                    :disabled="loadingDocuments.indexOf(index + 'W')!==-1"
                    @click="() => downloadDocument(index, true)"
                    href="#"
                    class="list-group-item list-group-item-action">
              <download-icon size="1x" />&nbsp;{{ document.document_caption }}
              <font-awesome-icon v-if="loadingDocuments.indexOf(index + 'W')!==-1" :icon="faSpinner" spin />
            </button>
            <a v-if="wordingDocuments.length == 0" class="list-group-item list-group-item-action text-muted">
              No wording documents attached to this policy
            </a>
          </div>
        </card>
      </div>
      <!-- Endorsements -->
      <policy-stats-card label="Endorsement"
                         :variant="hasActiveEndorsement(localPolicy) ? 'primary' : 'success'"
                         :badge-value="endorsementText"
                         :collapsed-stats-col-width="collapsedStatsColWidth" />
    </div>
  </div>
</template>
<script>
import PolicyStatsCard from "@common/src/components/PolicyStatsCard";
import PolicyMixin from "@clients/src/mixins/policy.mixin";
import DownloadsMixin from "@common/src/mixins/downloads.mixin";
import { DownloadIcon } from "vue-feather-icons";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ApiService from "@/services/api.service";

const DOCUMENT_TYPE_ID_WORDING = 1;

export default {
  name: "PolicyStatusList",
  components: {PolicyStatsCard, DownloadIcon},
  mixins: [PolicyMixin],
  props: {
    localPolicy: {
      type: Object,
      required: true,
    },
    collapsedStatsColWidth: {
      type: Number,
      default: 4
    },
  },
  methods: {
    downloadDocument(documentIndex, wordingDocument = false) {
      const loadingKey = documentIndex + (wordingDocument ? 'W' : 0);
      if (this.loadingDocuments.indexOf(loadingKey) !== -1) {
        return;
      }
      this.loadingDocuments.push(loadingKey);

      let document = this.nonWordingDocuments[documentIndex];
      if (wordingDocument) {
        document = this.wordingDocuments[documentIndex];
      }

      if (document === undefined) {
        console.log(`Document not found at id ${documentIndex} in document array`);
        return;
      }
      const label = document.document_caption;
      ApiService.get(
          process.env.VUE_APP_API_ENDPOINT + "/document/" + document.document_id_hash,
          {responseType: 'blob'}
      ).then(
        response => {
          if (!response || response.success === false) {
            this.loadingDocuments.splice(this.loadingDocuments.indexOf(loadingKey), 1);
            return;
          }
          const blob = new Blob([response.data]);
          this.$refs.downloadLink.href = URL.createObjectURL(blob);
          this.$refs.downloadLink.download = label;
          this.$refs.downloadLink.click();
          URL.revokeObjectURL(this.$refs.downloadLink.href);
          this.loadingDocuments.splice(this.loadingDocuments.indexOf(loadingKey), 1);
        }
      ).catch(console.error);
    },
  },
  data: () => ({
    loadingDocuments: [],
    faSpinner,
  }),
  computed: {
    timelinessText: function() {
      const timeliness = this.timelinessStatus(this.localPolicy);
      return timeliness?.satisfied===1
          ? 'Finalised ' + this.formatDateUKMixin(timeliness.date_completed) : 'Pending';
    },
    timelinessVariant: function() {
      const timeliness = this.timelinessStatus(this.localPolicy);
      return timeliness?.variant ?? 'secondary';
    },
    policyIssuanceText: function() {
      const policyIssuance = this.policyIssuanceStatus(this.localPolicy);
      return policyIssuance?.satisfied===1
          ? 'Policy Issued ' + this.formatDateUKMixin(policyIssuance.date_completed) : 'Pending';
    },
    policyIssuanceVariant: function() {
      const policyIssuance = this.policyIssuanceStatus(this.localPolicy);
      return policyIssuance?.variant ?? 'secondary';
    },
    premiumCollectedText: function() {
      const premiumReceipt = this.premiumReceiptStatus(this.localPolicy.lines);
      if (premiumReceipt?.satisfied===1) {
        return 'Paid ' + this.formatDateUKMixin(premiumReceipt.date_satisfied);
      } else {
        return 'Pending';
      }
    },
    premiumCollectedVariant: function() {
      const premiumReceipt = this.premiumReceiptStatus(this.localPolicy.lines);
      return premiumReceipt?.variant ?? 'secondary';
    },
    endorsementText: function() {
      return this.hasActiveEndorsement(this.localPolicy) ? 'In Progress' : 'No active endorsement';
    },
    documents: function() {
      return this.localPolicy ? this.localPolicy.documents : [];
    },
    nonWordingDocuments: function() {
      return this.localPolicy
          ? this.documents.filter(document => document.policy_document_type_id!=DOCUMENT_TYPE_ID_WORDING)
          : [];
    },
    wordingDocuments: function() {
      return this.localPolicy
          ? this.documents.filter(document => document.policy_document_type_id==DOCUMENT_TYPE_ID_WORDING)
          : [];
    }
  }
}
</script>
