<template>
  <div>
    <projects-table
      :per-page="10"
      type="dark"
      title="Instructed Accounts"
      :table-data="instructedAccounts"
      :loadingProgrammes="loadingProgrammes"
      v-if="$store.getters.isLoggedIn"
    >
    </projects-table>
    <div v-else>
      <div class="col">
        <p>Log in to see your Instructed Accounts</p>
        <login-modal @logged-in="reloadProgrammes"></login-modal>
        <button class="btn btn-primary" v-b-modal.modal-login>Login</button>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsTable from "@common/src/views/Tables/ProjectsTable";
import axios from "axios";
import FeaturesMixin from "@/mixins/features.mixin";
import { MASTER_POLICY_BIND_STATE } from "@common/src/constants";
import LoginModal from "@underwriters/src/components/LoginModal";

export default {
  name: "InstructedAccounts",
  components: { ProjectsTable, LoginModal },
  data: () => {
    return {
      loadingProgrammes: true,
      loadingStateId: 0,
      deletingStateId: 0,
      programmeList: []
    };
  },
  computed: {
    instructedAccounts() {
      return this.programmeList
        .filter(
          project =>
            project.mpol || project.completionStatus.name === "Submitted"
        )
        .map(programme => {
          return {
            ...programme,
            classType: this.parseClassTypeFromProgramme(programme),
            handler: this.getHandlerFromProgramme(programme),
            policyBindStateCaption: this.getPolicyBindStateCaption(
              programme?.mpol?.policy_bind_state_id
            ),
            period: this.getPolicyPeriod(programme)
          };
        })
        .sort((a, b) => (a?.mpol?.policy_bind_state_id ?? -1) - (b?.mpol?.policy_bind_state_id ?? -1))
    }
  },
  mixins: [FeaturesMixin],
  methods: {
    async reloadProgrammes() {
      this.programmeList = [];
      await this.getProgrammeList();
    },
    async getProgrammeList() {
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/list";

      this.loadingProgrammes = true;
      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        this.programmeList = data.programmes;
        this.loadingProgrammes = false;
      } catch (err) {
        console.error(err);
      }
    },
    async loadProgramme(id) {
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/" + id;
      this.loadingStateId = id;
      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        if (data.state) {
          await this.$store.dispatch("loadState", data);
          let lastUrlName = data.state.lastUrlName;
          if (
            data.state.localPolicies.completionStatus &&
            data.state.localPolicies.completionStatus !== "submitted"
          ) {
            this.$toasted.info(
              "This Programme has already been submitted."
            );
          }
          if (this.underwritingPortalMode()) {
            await this.$router.push({ name: "Add Data to Inquiry", params: { inquiryId: id } });
          } else if (lastUrlName) {
            await this.$router.push({ name: lastUrlName });
          } else {
            await this.$router.push({ name: "Country Select" });
          }
          this.loadingStateId = 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async deleteProgramme(id) {
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/" + id;

      this.deletingStateId = id;
      try {
        let { data } = await axios.delete(url, {
          withCredentials: true
        });
        this.programmeList = data.programmes;
        this.deletingStateId = 0;
        this.$toasted.success("Programme successfully deleted");
      } catch (err) {
        console.error(err);
      }
    },
    parseClassTypeFromProgramme(programme) {
      const classType = programme.classType;
      if (typeof classType === "string") {
        return classType;
      }
      return classType.policy_class_type_caption;
    },
    getHandlerFromProgramme(programme) {
      let sForename = programme?.mpol?.handlers?.primaryHandler?.forename ?? '';
      let sSurname = programme?.mpol?.handlers?.primaryHandler?.surname ?? '';

      if (sForename || sSurname) {
        return sForename + " " + sSurname;
      } else {
        return '<em class="text-muted">Not Assigned</em>';
      }
    },
    getPolicyBindStateCaption(iPolicyBindStateId) {
      let sStateCaption = "";

      if (iPolicyBindStateId) {
        sStateCaption = Object.keys(MASTER_POLICY_BIND_STATE)
          .find(key => MASTER_POLICY_BIND_STATE[key] === iPolicyBindStateId)
          .toLowerCase()
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      } else {
        sStateCaption = "Pending";
      }

      let sStateClass = "default";

      switch (iPolicyBindStateId) {
        case MASTER_POLICY_BIND_STATE.BOUND:
          sStateClass = "success";
          break;
        case MASTER_POLICY_BIND_STATE.NOT_BOUND:
          sStateClass = "warning";
          break;
        case MASTER_POLICY_BIND_STATE.PROSPECT:
          sStateClass = "danger";
          break;
        default:
          sStateClass = "secondary";
      }

      return `<span class="badge badge-${sStateClass}">${sStateCaption}</span>`;
    },
    getPolicyPeriod(programme) {
      return programme.mpol
        ? this.formatDateUKMixin(programme.mpol.inception) +
            " - " +
            this.formatDateUKMixin(programme.mpol.expiry)
        : " - ";
    },
    setLoadingProgrammes(loading) {
      this.loadingProgrammes = loading;
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.getProgrammeList();
    }
  }
};
</script>
